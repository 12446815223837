import NavBar from '@/componets/NavBar/NavBar.js'

import { Outlet } from 'react-router-dom'

const LayOut = () => {
  return (
    <div>
      {/* 导航栏区域 */}
      <NavBar />
      <Outlet />
    </div>
  )
}

export default LayOut
