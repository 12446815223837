import React, { useState } from 'react'
import './index.scss'
import FAQItem from '@/componets/FAQItem/FAQItem.js'
import FooterNav from '@/componets/FooterNav/FooterNav.js'
import GradientButton from '@/componets/Button/Button.js'
import { useNavigate } from 'react-router-dom'

const Faq = () => {
  const navigate = useNavigate()

  const questions_lst = [
    {
      title: 'question1',
      question: 'Q: What is the funding rate?',
      p1: 'Cryptocurrencies offer perpetual futures, similar to how traditional assets offer fixed-time futures.',
      p2: 'Each perpetual futures trade needs two sides, one that believes that the markets will rise and another that believes the market will fall. During bullish times, there will naturally be fewer people to take the bearish position. To incentivize investors to take the short position, the long position will offer a fee (funding rate) to the short sellers.',
      p3: '',
      img_flag: false
    },
    {
      title: 'question2',
      question:
        'Q: When you sell the perpetual futures to collect the funding rate, aren’t you exposed to market risk?',
      p1: 'We deploy an arbitrage strategy that hedges the exposure to market movements: First we sell the perpetual future. Then we buy the underlying asset. This way there is limited exposure to market movements while we safely collect the funding rate / fee.',
      p2: '',
      p3: '',
      img_flag: true
    },
    {
      title: 'question3',
      question: 'Q: How do you mitigate the risk from your trading strategies?',
      p1: 'Counterparty risk: If the exchange on which is traded goes bankrupt, it will be difficult to redeem funds held on that exchange. To minimize this risk, we prefer to deploy our strategies across a few different exchanges.',
      p2: 'Liquidation risk: These arbitrage strategies can be leveraged. However, with higher leverage, the risk of being liquidated is higher as well. To mitigate the liquidation risk, a few safety measures have been put in place: a) our trading strategies apply a maximum of 3x leverage b) on a constant basis, leverage ratios are being monitored and trades are rebalanced accordingly c) an alert system has been deployed to alert us of unusual high volatility in the markets.',
      p3: 'Funding rate risk: The funding rate varies. Factors influencing the funding rate include the buying and selling pressure of a cryptocurrency, the open interest in a cryptocurrencies perpetual future, the exchange the derivatives are traded on etc. In bear markets the funding rate can turn negative, our systems continuously monitor the funding rates and alert us if the funding rate turns unprofitable.',
      img_flag: false
    },
    {
      title: 'question4',
      question:
        'Q: Funding rate arbitrage is not new, what is special about you?',
      p1: 'Funding rate arbitrage in crypto has been a success story for a long time. It being a long-proven concept is perfect, because this de-risks the strategy. However, funding rate arbitrages have been almost exclusively accessible to tech savvy investors. Even for these individuals, the process of executing the arbitrage strategy is plagued by manual processes, from always identifying the most liquid perpetual crypto futures, to rebalancing the hedge ratios and continuously monitoring funding rates. While there are automated trading bots offering solutions, these bots usually require your funds to be held in an opaque 3rd party custodian account at the discretion of faceless teams.',
      p2: '',
      p3: '',
      img_flag: false
    },
    {
      title: 'question5',
      question: 'Q: How does your non-custodial solution work?',
      p1: 'Most solutions to participate in funding rate arbitrage require you to deposit your funds in anonymous wallets of the trading strategy providers, thereby allowing these faceless providers to execute the trades from their wallet while leaving you at their mercy to eventually return your money.',
      p2: 'We reverse this dependency. We use an API to connect our trading system to your exchange account. The API, and therefore our trading system, only has trading permissions to execute the arbitrage strategies. However, we do not have permission to withdraw any funds. As such, you are in control of your crypto, you can monitor all our actions, and you can exit the strategy anytime.',
      p3: '',
      img_flag: false
    }
  ]

  const [openIndex, setOpenIndex] = useState(null)

  return (
    <div className="faq-container">
      <div className="faq_liner_top_div gradient-text">
        Frequently Asked Questions
      </div>

      {/* 问题提问页面 */}
      <div className="faq_question_div">
        <div className="faq_question_item">
          {questions_lst.map((item, index) => (
            <FAQItem
              key={item.title}
              question={item.question}
              p1={item.p1}
              p2={item.p2}
              p3={item.p3}
              img_flat={item.img_flag}
              isOpen={openIndex === index}
              onClick={() => {
                setOpenIndex(openIndex === index ? null : index)
              }}
            />
          ))}
        </div>
        {/* 最底下的按钮区域 */}
        <div className="faq-bottom-btn-1">
          <GradientButton
            text="Contact Us To Learn More"
            width="249px"
            height="40px"
            fontSize="16px"
            fontWeight="600"
            onClick={() => navigate('/contact-us')}
          />
        </div>
      </div>
      <div style={{ height: 20 + 'px' }}></div>
      <div className="pc_footerNav">
        <FooterNav />
      </div>
    </div>
  )
}

export default Faq
