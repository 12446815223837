import { request } from '@/utils/index.js' // 引入封装好的 axios 实例

// 获取所有策略数据
const getStrategySummary = () => {
  return request({
    url: '/api/strategy_summary_all',
    method: 'GET'
  })
}

// 获取特定策略名称的事实表
const getFactSheet = (strategy) => {
  return request({
    url: `/api/fact_sheet?strategy_name=${strategy}`,
    method: 'GET'
  })
}

// 获取特定策略名称和时间窗口的策略历史数据
const getStrategyHistory = (strategy, timeWindow) => {
  return request({
    url: `/api/strategy_history?strategy_name=${strategy}&time_window=${timeWindow}`,
    method: 'GET'
  })
}

// 联系我们页面
const contactUs = (formData) => {
  return request({
    url: '/contact_info',
    method: 'POST',
    data: formData
  })
}
export { getStrategySummary, getFactSheet, getStrategyHistory, contactUs }
