import React from 'react'
import './FailedIcon.scss'

const FailedIcon = () => {
  return (
    <div className="container">
      <div className="failed-icon">
        <div className="line line1"></div>
        <div className="line line2"></div>
      </div>
    </div>
  )
}

export default FailedIcon
