import React from 'react'
import './index.scss'
import FooterNav from '@/componets/FooterNav/FooterNav.js'

const Disclaimer = () => {
  return (
    <div className="disclaimer_container">
      <div className="disclaimer_title gradient-text">
        Disclaimer and Risk Warning
      </div>
      <div className="disclaimer_content-box">
        <div>
          By accessing StoneRiver’s website and/or using any of the StoneRiver
          services, you agree to and acknowledge the following:-
        </div>
        <div>
          1. To the maximum extent permitted under applicable law, any and all
          services, information, materials or other items provided by or on
          behalf of StoneRiver are offered on an “as is” and “as available”
          basis, and StoneRiver expressly disclaims, and you waive, any and all
          warranties of any kind including, without limitation, merchantability,
          fitness for a particular purpose, title or non-infringement or
          otherwise. StoneRiver does not represent or warrant any information
          available or displayed on its website, StoneRiver services or
          StoneRiver materials are accurate, complete, reliable, current,
          error-free, or free of viruses or other harmful components.
        </div>
        <div>
          2. StoneRiver does not guarantee that any order will be executed,
          accepted, recorded or remain open. Except for the express statements,
          agreements and rules set forth in these terms, you hereby acknowledge
          and agree that you have not relied upon any other statement or
          agreement, whether written or oral, with respect to your use and
          access of StoneRiver services.
        </div>
        <div>
          3. StoneRiver will not be liable for any losses or damages arising out
          of or relating to: (a) any inaccuracy, defect or omission of digital
          assets price or other types of data, (b) any error or delay in the
          transmission of such data, (c) interruption in any such data, (d)
          regular or unscheduled maintenance carried out by StoneRiver and
          service interruption and change resulting from such maintenance, (e)
          any damages incurred by other users’ actions, omissions or violation
          of these terms, (f) any damage caused by illegal actions of other
          third parties or actions without authorized by StoneRiver; and (g)
          other exemptions mentioned in disclaimers and platform rules issued by
          StoneRiver.
        </div>
        <div>
          4. To the maximum extent permitted by applicable law, in no event will
          StoneRiver, its affiliates and their respective shareholders, members,
          directors, officers, employees, attorneys, agents, representatives,
          suppliers or contractors be liable for any incidental, indirect,
          special, punitive, consequential or similar damages or liabilities
          whatsoever (including, without limitation, damages for loss of data,
          information, revenue, profits or other businesses or financial
          benefits) arising out of StoneRiver services, any performance or
          non-performance of StoneRiver services, or any other product, service
          or other item provided by or on behalf of StoneRiver and its
          affiliates, whether under contract, statute, strict liability or other
          theory even if StoneRiver has been advised of the possibility of such
          damages except to the extent of a final judicial determination that
          such damages were a result of StoneRiver’s gross negligence, fraud,
          willful misconduct or intentional violation of law.
        </div>
        <div>
          5. To the maximum extent permitted by applicable law, in no event will
          the liability of StoneRiver, its affiliates and their respective
          shareholders, members, directors, officers, employees, attorneys,
          agents, representatives, suppliers or contractors arising out of
          services offered by or on behalf of StoneRiver and its affiliates, any
          performance or non-performance of StoneRiver services, or any other
          product, service or other item, whether under contract, statute,
          strict liability or other theory, exceed the amount of the fees paid
          by you to StoneRiver in the twelve-month period immediately preceding
          the event giving rise to the claim for liability.
        </div>
        <div>
          6. You acknowledge and agree that: (1) you are aware of the risks
          associated with transactions of digital currencies and their
          derivatives; (2) you shall assume all risks related to the use of
          StoneRiver services and transactions of digital currencies and their
          derivatives; and (3) StoneRiver shall not be liable for any such risks
          or adverse outcomes.
        </div>
        <div>
          7. You represent and warrant that you have not been included in any
          trade embargoes or economic sanctions list (such as the United Nations
          security council sanctions list), the list of specially designated
          nationals maintained by OFAC (the office of foreign assets control of
          the U.S. department of the treasury), or the denied persons or entity
          list of the U.S. department of commerce. StoneRiver reserves the right
          to choose markets and jurisdictions to conduct business, and may
          restrict or refuse, in its discretion, the provision of StoneRiver
          services in certain countries or regions.
        </div>
        <div>
          8. You represent and warrant that your funds to be used on the
          StoneRiver platform or for StoneRiver services come from legitimate
          sources and do not originate from illegal activities; and you agree
          that StoneRiver may require you to provide or otherwise collect
          necessary information and materials as per relevant laws or government
          orders to verify the legality of the sources and use of you funds. You
          acknowledge that by interacting with StoneRiver’s Application
          Programming Interfaces (“API”), you (a) have understood the service,
          as well as the services’ risk and benefits (b) agree with the
          algorithms mechanics and its communication and commands of other 3rd
          party providers who you have relationships with, such as
          crypto-currency exchanges.
        </div>
        <div>
          9. No communication or information provided to you by StoneRiver is
          intended as, or shall be considered or construed as, investment
          advice, financial advice, trading advice, or any other sort of advice.
          You are solely responsible for determining whether any investment,
          investment strategy or related transaction is appropriate for you
          according to your personal investment objectives, financial
          circumstances and risk tolerance. Any decision to use our products or
          services is made solely by you.
        </div>
        <div>
          10. StoneRiver is not your broker, intermediary, agent, or advisor and
          has no fiduciary relationship or obligation to you in connection with
          any trades or other decisions or activities undertaken by you using
          StoneRiver services. StoneRiver does not monitor whether your use of
          StoneRiver services is consistent with your financial goals and
          objectives. It is up to you to assess whether your financial resources
          are adequate for your financial activity with us, and to your risk
          appetite in the products and services you use.
        </div>
        <div>
          11. You are solely responsible for determining what taxes you might be
          liable to, and how they apply, when transacting through the StoneRiver
          services. It is your sole responsibility to report and pay any taxes
          that may arise from transacting through StoneRiver services, and you
          acknowledge that StoneRiver does not provide legal or tax advice in
          relation to these transactions.
        </div>
        <div>
          12. Digital asset trading is subject to high market risk and price
          volatility. Changes in value may be significant and may occur rapidly
          and without warning. Past performance is not a reliable indicator of
          future performance. The value of an investment and any returns can go
          down as well as up, and you may not get back the amount you had
          invested.
        </div>
        <div>
          13. Digital assets may have limited liquidity which may make it
          difficult or impossible for you to sell or exit a position when you
          wish to do so. This may occur at any time, including at times of rapid
          price movements.
        </div>
        <div>
          14. We do not guarantee that the StoneRiver services will be available
          at any particular time or that StoneRiver services will not be subject
          to unplanned service outages or network congestion. It may not be
          possible for you to buy, sell, store, transfer, send or receive
          digital assets when you wish to do so.
        </div>
        <div>
          15. It is not possible for StoneRiver to eliminate all security risks.
          You are responsible for keeping your StoneRiver account password safe.
          Transactions in digital assets may be irreversible, and losses due to
          fraudulent or unauthorised transactions may not be recoverable.
        </div>
        <div>
          16. Given the nature of digital assets and their underlying
          technologies, there are a number of intrinsic risks, including but not
          limited to:
          <div>
            (1) faults, defects, hacks, exploits, errors, protocol failures or
            unforeseen circumstances occurring in respect of a digital asset or
            the technologies or economic systems on which the digital asset
            rely;
          </div>
          <div>
            (2) transactions in digital assets being irreversible. consequently,
            losses due to fraudulent or accidental transactions may not be
            recoverable;
          </div>
          <div>
            (3) technological development leading to the obsolescence of a
            digital asset;
          </div>
          <div>
            (4) delays causing a transactions not be settled on the scheduled
            delivery date; and
          </div>
          <div>
            (5) attacks on the protocol or technologies on which a digital asset
            depends, including, but not limited to: (i) distributed denial of
            service; (ii) sybil attacks; (iii) phishing; (iv) social
            engineering; (v) hacking; (vi) smurfing; (vii) malware; (viii)
            double spending; (ix) majority-mining, consensus-based or other
            mining attacks; (x) misinformation campaigns; (xi) forks; and (xii)
            spoofing.
          </div>
        </div>
        <div>
          17. When you communicate with us via electronic communication, you
          should be aware that electronic communications can fail, can be
          delayed, may not be secure and/or may not reach the intended
          destination.
        </div>
        <div>
          18. Changes in laws and regulations may materially affect the value of
          digital assets. This risk is unpredictable and may vary from market to
          market.
        </div>
      </div>
      <div className="pc_footerNav">
        <FooterNav />
      </div>
    </div>
  )
}

export default Disclaimer
