import React, { useState } from 'react'
import './NavBar.scss'
import { useNavigate, Link } from 'react-router-dom'
import { removeToken, getToken } from '@/utils/token.js'
import DropdownMenu from '@/componets/DropDownMenu/DropDownMenu.js'
import GradientButton from '@/componets/Button/Button.js'

const NavBar = () => {
  const navigate = useNavigate()
  const [menuItemVisible, setMenuItemVisible] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)
  const [StrategiesVisible, setStrategiesVisible] = useState(false)

  const StrategiesPathList = [
    { title: 'Balance', path: '/strategy?strategy=balance' },
    { title: 'Steady', path: 'strategy?strategy=steady' },
    { title: 'Turbo', path: '/strategy?strategy=turbo' }
  ]

  const Portfilio = [
    { title: 'profit', path: '/profit' },
    { title: 'bills', path: '/bills' },
    { title: 'profile', path: '/profile' }
  ]

  const token = getToken()

  const btnHandler = () => {
    if (token) {
      removeToken()
      navigate('/login')
    } else {
      navigate('/login')
    }
  }

  const handleMenuClick = () => {
    setMenuVisible((prevState) => {
      const newState = !prevState

      if (newState) {
        setTimeout(() => {
          setMenuVisible(false)
        }, 3000)
      }
      return newState
    })
  }

  const handleLinkClick = () => {
    setMenuVisible(false)
  }

  return (
    <div className="navBar_container">
      <div className="left_menu_icon">
        {/*菜单栏实现 */}
        <div className={`menu-container ${menuVisible ? 'visible' : ''}`}>
          <div className="menu-icon" onClick={handleMenuClick}>
            &#9776;
          </div>
          {menuVisible && (
            <div className="dropdown-menu">
              <Link to="/strategy?strategy=steady" onClick={handleLinkClick}>
                Steady
              </Link>
              <Link to="/strategy?strategy=balance" onClick={handleLinkClick}>
                Balanced
              </Link>
              <Link to="/strategy?strategy=turbo" onClick={handleLinkClick}>
                Turbo
              </Link>
            </div>
          )}
        </div>
        <div
          className="img"
          onClick={() => {
            navigate('/')
          }}
        ></div>
        <div
          className="pc_right_title lexend-font"
          onClick={() => {
            navigate('/')
          }}
        >
          STONERIVER TECHNOLOGY
        </div>
      </div>

      <div className="right_btn">
        <div className="pc_Strategies_menu">
          <div
            className="pc_contact_strategies"
            onClick={() => {
              setStrategiesVisible((prevState) => {
                const newState = !prevState

                if (newState) {
                  setTimeout(() => {
                    setStrategiesVisible(false)
                  }, 3000)
                }

                return newState
              })
            }}
          >
            <div
              className="pc_navbar_strategies"
              style={{ fontSize: 14 + 'px', fontWeight: 500 }}
            >
              Strategies
            </div>
            <span className="arrow-bottom"></span>
            {StrategiesVisible && <DropdownMenu pathdic={StrategiesPathList} />}
          </div>
        </div>

        <div className="pc_contact_us" onClick={() => navigate('/contact-us')}>
          Contact Us
        </div>
        {token && (
          <div className="myPortfilio_btn_flex">
            <div
              className="navbar-myPortfilio"
              onClick={() => {
                setMenuItemVisible((prevState) => {
                  const newState = !prevState

                  if (newState) {
                    setTimeout(() => {
                      setMenuItemVisible(false)
                    }, 3000)
                  }

                  return newState
                })
              }}
            >
              <div className="navbar_profilio">My Portfilio</div>
              <span className="arrow-bottom"></span>
              {menuItemVisible && <DropdownMenu pathdic={Portfilio} />}
            </div>
          </div>
        )}
        <GradientButton
          onClick={btnHandler}
          text={token ? 'Log out' : 'Login'}
          width="86px"
          height="36px"
        />
      </div>
    </div>
  )
}

export default NavBar
