import React, { Suspense, lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Loading from '@/componets/Loading/Loading.js'

import Login from '@/pages/Login/index.js'
import Layout from '@/pages/Layout/index.js'
import Home from '@/pages/Home/index.js'
import SignUp from '@/pages/SignUp/index.js'
import ContactUs from '@/pages/ContactUs/index.js'
import Faq from '@/pages/Faq/index.js'
import Disclaimer from '@/pages/Disclaimer/index.js'
import Term from '@/pages/Term/index.js'
const Strategy = lazy(() => import('@/pages/Strategy/index.js'))
const Profit = lazy(() => import('@/pages/Profit/index.js'))
const Bills = lazy(() => import('@/pages/Bills/index.js'))
const Profile = lazy(() => import('@/pages/Profile/index.js'))

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        )
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/signup',
        element: <SignUp />
      },
      {
        path: '/contact-us',
        element: <ContactUs />
      },
      {
        path: '/faq',
        element: <Faq />
      },
      {
        path: '/disclaimer',
        element: <Disclaimer />
      },
      {
        path: '/terms-and-conditions',
        element: <Term />
      },
      {
        path: '/strategy',
        element: (
          <Suspense fallback={<Loading />}>
            <Strategy />
          </Suspense>
        )
      },
      {
        path: '/profit',
        element: (
          <Suspense fallback={<Loading />}>
            <Profit />
          </Suspense>
        )
      },
      {
        path: '/bills',
        element: (
          <Suspense fallback={<Loading />}>
            <Bills />
          </Suspense>
        )
      },
      {
        path: '/profile',
        element: (
          <Suspense fallback={<Loading />}>
            <Profile />
          </Suspense>
        )
      }
    ]
  }
])

export default router
