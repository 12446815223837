import React from 'react'
import { NavLink } from 'react-router-dom'
import './FooterNav.scss' // 导入 SCSS 文件

const FooterNav = () => {
  return (
    <footer className="footer-nav">
      <nav>
        <NavLink
          to="/faq"
          className={({ isActive }) => (isActive ? 'gradient-text' : '')}
        >
          FAQ
        </NavLink>
        <span>|</span>
        <NavLink
          to="/contact-us"
          className={({ isActive }) => (isActive ? 'gradient-text' : '')}
        >
          Contact Us
        </NavLink>
        <span>|</span>
        <NavLink
          to="/disclaimer"
          className={({ isActive }) => (isActive ? 'gradient-text' : '')}
        >
          Disclaimer
        </NavLink>
        <span>|</span>
        <NavLink
          to="/terms-and-conditions"
          className={({ isActive }) => (isActive ? 'gradient-text' : '')}
        >
          Terms & Conditions
        </NavLink>
      </nav>
    </footer>
  )
}

export default FooterNav
