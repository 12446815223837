import React from 'react'
import { Form, Input, Button, Toast } from 'antd-mobile'
import './index.scss'
// import { useSelector } from 'react-redux'
// import Loading from '@/componets/Loading/Loading.js'
import { useDispatch } from 'react-redux'
import { fetchLogin } from '@/store/modules/private.js'
import { useNavigate } from 'react-router-dom'
import CheckMark from '@/componets/CheckMark/Checkmark.js'
import Failedicon from '@/componets/FailedIcon/FailedIcon.js'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const loading = useSelector((state) => state.private.loading)

  // 表单校验成功后的提交函数
  const onFinish = async (value) => {
    //触发我们异步函数
    value.username = value.username.toLowerCase()
    console.log(value)
    const res = await dispatch(fetchLogin(value))
    if (res.success) {
      // navigate('/contact-us')
      navigate('/profit')
      Toast.show({
        icon: <CheckMark />,
        duration: 3000 // 持续时间，以毫秒为单位
      })
    } else {
      Toast.show({
        icon: <Failedicon />,
        content: res.message,
        duration: 3000
      })
    }
  }
  // if (loading) {
  //   return <Loading /> // 显示加载状态
  // }
  return (
    <div className="login-wrapper">
      <div className="login-container">
        <h2>Login</h2>
        <Form
          layout="horizontal"
          onFinish={onFinish}
          initialValues={{ email: '', password: '' }} // 设置初始值
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              className="login-button"
            >
              Login
            </Button>
          }
        >
          <div className="field-container">
            <div className="field-label">Email*</div>
            <Form.Item
              name="username"
              rules={[
                { required: true, message: 'Email is required' }
                // { type: 'email', message: 'The input is not valid E-mail!' }
              ]}
            >
              <Input id="email" placeholder="Your email" />
            </Form.Item>
          </div>

          <div className="field-container">
            <div className="field-label">Password*</div>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Password is required' },
                { min: 8, message: 'Password must be at least 8 characters' },
                {
                  max: 16,
                  message: 'Password cannot be longer than 16 characters'
                }
              ]}
            >
              <Input
                id="password"
                type="password"
                placeholder="Enter password"
              />
            </Form.Item>
          </div>
        </Form>
        <p className="signup-link">
          Don't have an account? <a href="/signup">Sign Up here</a>
        </p>
      </div>
    </div>
  )
}

export default Login
