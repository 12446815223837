import './Loading.scss'
import { SpinLoading } from 'antd-mobile'

const Loading = () => {
  return (
    <div className="loading_flex">
      <SpinLoading color="primary" />
    </div>
  )
}

export default Loading
