import { createSlice } from '@reduxjs/toolkit'
import { _setToken, getToken } from '@/utils/token.js'
import {
  loginAPI,
  signUpAPI,
  getClientProfitSummary,
  getClientApySummary,
  getClientInvestOverview,
  getClientStrategyHistoryApy,
  getClientStrategyHistoryProfit,
  getClientBills,
  getClientProfile,
  addApyKey,
  verifyCodeAPI
} from '@/apis/private.js'

const privateStore = createSlice({
  name: 'private',
  initialState: {
    token: getToken() ? getToken() : '', // 初始化 token

    clientProfitSummary: {}, // 客户利润摘要
    clientApySummary: {}, // 客户 APY 摘要
    clientInvestOverview: [], // 客户投资概览
    strategyHistoryAPY: {}, // 策略历史 APY
    strategyHistoryProfit: {}, // 策略历史利润
    apyFromChartDuration: 0,
    payment: [], // 支付信息
    profile: {} // 客户档案
  },
  reducers: {
    // 设置 token
    setToken(state, action) {
      state.token = action.payload
      _setToken(action.payload)
    },
    // 设置客户利润摘要
    setClientProfitSummary(state, action) {
      state.clientProfitSummary = action.payload
    },
    // 设置客户 APY 摘要
    setClientApySummary(state, action) {
      state.clientApySummary = action.payload
    },
    // 设置客户投资概览
    setClientInvestOverview(state, action) {
      state.clientInvestOverview = action.payload
    },
    // 设置策略历史 APY
    setStrategyHistoryAPY(state, action) {
      state.strategyHistoryAPY = action.payload
    },
    // 设置策略历史利润
    setStrategyHistoryProfit(state, action) {
      state.strategyHistoryProfit = action.payload
    },
    // 设置支付信息
    setPayment(state, action) {
      state.payment = action.payload
    },
    // 设置客户档案
    setProfile(state, action) {
      state.profile = action.payload
    }
  }
})

const {
  setToken,
  setClientProfitSummary,
  setClientApySummary,
  setClientInvestOverview,
  setStrategyHistoryAPY,
  setStrategyHistoryProfit,
  setPayment,
  setProfile
} = privateStore.actions

// 获取客户账单信息的异步请求
const fetchGetClientBills = () => {
  return async (dispatch) => {
    try {
      const res = await getClientBills()
      const formattedData = Object.keys(res).map((key) => ({
        ...res[key],
        quarter: key
      }))
      dispatch(setPayment(formattedData))
    } catch (error) {
      console.error('Failed to fetch client bills:', error)
    }
  }
}

// 登录异步请求
const fetchLogin = (loginFormData) => {
  return async (dispatch) => {
    try {
      const res = await loginAPI(loginFormData)
      if (res.message === 'Login successful') {
        dispatch(setToken(res.token))
        return { success: true, message: res.message }
      } else if (res?.message === 'Invalid username or password') {
        return { success: false, message: 'Invalid username or password' }
      } else {
        return {
          success: false,
          message: res?.message || 'somthing is wrong. Please try again later!'
        }
      }
    } catch (error) {
      return {
        success: false,
        message: 'somthing is wrong. Please try again later!'
      }
    }
  }
}

// 注册异步请求
const fetchSignUp = (signupFormData) => {
  return async () => {
    try {
      const res = await signUpAPI(signupFormData)
      if (res.message === 'success' && res.success === true) {
        return {
          success: true,
          message:
            'The verification code was sent successfully. Please verify the mailbox.'
        }
      } else {
        return { success: false, message: res?.message }
      }
    } catch (error) {
      return {
        success: false,
        message: 'somthing is wrong. Please try again later!'
      }
    }
  }
}

// 获取客户利润摘要
const fetchGetClientProfitSummary = () => {
  return async (dispatch) => {
    try {
      const res = await getClientProfitSummary()
      dispatch(setClientProfitSummary(res))
    } catch (error) {
      console.error('Failed to fetch client profit summary:', error)
    }
  }
}

// 获取客户 APY 摘要
const fetchGetClientApySummary = () => {
  return async (dispatch) => {
    try {
      const res = await getClientApySummary()
      dispatch(setClientApySummary(res))
    } catch (error) {
      console.error('Failed to fetch client APY summary:', error)
    }
  }
}

// 获取客户投资概览
const fetchGetClientInvestOverview = () => {
  return async (dispatch) => {
    try {
      const res = await getClientInvestOverview()
      dispatch(setClientInvestOverview(res))
    } catch (error) {
      console.error('Failed to fetch client investment overview:', error)
    }
  }
}

// 获取策略历史 APY
const fetchGetClientStrategyHistoryApy = (timeWindow) => {
  return async (dispatch) => {
    try {
      const res = await getClientStrategyHistoryApy(timeWindow)
      dispatch(setStrategyHistoryAPY(res))
    } catch (error) {
      console.error('Failed to fetch strategy history APY:', error)
    }
  }
}

// 获取策略历史利润
const fetchGetClientStrategyHistoryProfit = (timeWindow) => {
  return async (dispatch) => {
    try {
      const res = await getClientStrategyHistoryProfit(timeWindow)
      dispatch(setStrategyHistoryProfit(res))
    } catch (error) {
      console.error('Failed to fetch strategy history profit:', error)
    }
  }
}

// 获取客户档案
const fetchGetClientProfile = () => {
  return async (dispatch) => {
    try {
      const res = await getClientProfile()
      dispatch(setProfile(res))
    } catch (error) {
      console.error('Failed to fetch client profile:', error)
    }
  }
}

// 添加API密钥
const fetchAddApyKey = (key_name, key_value) => {
  return async () => {
    try {
      const res = await addApyKey(key_name, key_value)
      return res
    } catch (error) {
      console.error('Failed to add API key:', error)
    }
  }
}

// 校验验证码
const fetchPostVerifyCode = (formData) => {
  return async () => {
    try {
      const res = await verifyCodeAPI(formData)
      if (res?.message === 'Verification code valid') {
        return { success: true, message: 'Registration succeeded !' }
      } else if (res?.message === 'Invalid verification code') {
        return { success: false, message: 'Invalid verification code' }
      } else if (res?.message === 'No code requested for this user') {
        return { success: false, message: 'Verifacation code expired' }
      } else {
        return { success: false, message: res?.message }
      }
    } catch (error) {
      return {
        success: false,
        message: 'Something is wrong ,please try again!'
      }
    }
  }
}

const privateReducer = privateStore.reducer

export {
  fetchLogin,
  fetchSignUp,
  fetchGetClientProfitSummary,
  fetchGetClientApySummary,
  fetchGetClientInvestOverview,
  fetchGetClientStrategyHistoryApy,
  fetchGetClientStrategyHistoryProfit,
  fetchGetClientBills, // 导出获取客户账单信息的异步请求
  fetchGetClientProfile, // 导出获取客户档案的异步请求
  fetchAddApyKey, // 导出添加API密钥的异步请求
  fetchPostVerifyCode
}

export default privateReducer
