//封装token相关的方法
const TOKEN_KEY = 'token_key'
function _setToken(token) {
  sessionStorage.setItem(TOKEN_KEY, token)
}

function getToken() {
  return sessionStorage.getItem(TOKEN_KEY)
}

function removeToken() {
  sessionStorage.removeItem(TOKEN_KEY)
}

export { _setToken, getToken, removeToken }
