import React from 'react'
import PropTypes from 'prop-types'
import './Button.scss'

const GradientButton = ({
  width = '100px',
  height = '40px',
  borderWidth = 2,
  fontColor = '',
  fontSize = '14px',
  fontWeight = '600',
  onClick = () => {},
  text
}) => {
  const containerStyle = {
    borderRadius: `${parseInt(height) / 2}px`,
    padding: borderWidth,
    background: 'linear-gradient(90deg, #FC00FF 0%, #0066DE 100%)'
  }

  const buttonStyle = {
    width,
    height,
    fontSize,
    fontWeight,
    borderRadius: `${parseInt(height) / 2 - borderWidth}px`,
    color: fontColor || 'transparent',
    borderWidth: borderWidth
  }

  return (
    <div className="button-container" style={containerStyle}>
      <button className="button" style={buttonStyle} onClick={onClick}>
        <span
          className={fontColor ? '' : 'text-gradient'}
          style={{ color: fontColor || 'transparent' }}
        >
          {text}
        </span>
      </button>
    </div>
  )
}

GradientButton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderWidth: PropTypes.number,
  fontColor: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired
}

export default GradientButton
