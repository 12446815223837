import { createSlice } from '@reduxjs/toolkit'
import {
  getStrategySummary,
  getFactSheet,
  getStrategyHistory,
  contactUs
} from '@/apis/public'

const publicStore = createSlice({
  name: 'public',
  initialState: {
    strategy_summary_all: {},
    fact_sheet: {},
    strategy_history: {},
    strategy_apy_values: {} // 新增变量
  },
  reducers: {
    setStrategySummaryAll(state, action) {
      state.strategy_summary_all = action.payload
    },
    setFactSheet(state, action) {
      state.fact_sheet = action.payload
    },
    setStrategyHistory(state, action) {
      state.strategy_history = action.payload
    },
    setStrategyApyValues(state, action) {
      state.strategy_apy_values = action.payload
    }
  }
})

const {
  setStrategySummaryAll,
  setStrategyApyValues,
  setFactSheet,
  setStrategyHistory
} = publicStore.actions

// 异步 thunk 获取所有策略数据
const fetchGetStrategySummary = () => {
  return async (dispatch) => {
    try {
      const res = await getStrategySummary()
      dispatch(setStrategySummaryAll(res))

      // 提取 APY 值
      const apyValues = Object.keys(res).reduce((acc, strategy) => {
        acc[strategy] = res[strategy].apy
        return acc
      }, {})

      // 更新 strategy_apy_values
      dispatch(setStrategyApyValues(apyValues))
    } catch (error) {
      console.error(error.message)
    }
  }
}

// 异步 thunk 获取特定策略名称的事实表
const fetchGetFactSheet = (strategy) => {
  return async (dispatch) => {
    try {
      const res = await getFactSheet(strategy)
      dispatch(setFactSheet(res))
    } catch (error) {
      console.error(error.message)
    }
  }
}

// 异步 thunk 获取特定策略名称和时间窗口的策略历史数据
const fetchGetStrategyHistory = (strategy, timeWindow) => {
  return async (dispatch) => {
    try {
      const res = await getStrategyHistory(strategy, timeWindow)
      dispatch(setStrategyHistory(res))
    } catch (error) {
      console.error(error.message)
    }
  }
}

// 异步 thunk 提交 contact us 表单信息
const fetchPostContactUs = (formData) => {
  return async () => {
    try {
      const res = await contactUs(formData)
      if (res.message === 'Contact information received successfully') {
        return { success: true, message: res.message }
      } else {
        return { success: false, message: 'Incorrect Data' }
      }
    } catch (error) {
      return { success: false, message: error }
    }
  }
}

const publicReducer = publicStore.reducer

export {
  setStrategySummaryAll,
  fetchGetStrategySummary,
  fetchGetFactSheet,
  fetchGetStrategyHistory,
  fetchPostContactUs
}

export default publicReducer
