import React, { useRef, useEffect, useState } from 'react'
import './FAQItem.scss'

const FAQItem = ({ question, p1, p2, p3, img_flat, isOpen, onClick }) => {
  const contentRef = useRef(null)
  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    if (isOpen) {
      setMaxHeight(contentRef.current.scrollHeight)
    } else {
      setMaxHeight(0)
    }
  }, [isOpen])

  return (
    <div className="faq-item">
      <div className="faq-title">
        <span onClick={onClick}>{question}</span>
        <button
          onClick={onClick}
          className={`toggle-button ${isOpen ? 'open' : ''}`}
        >
          <span className="arrow"></span>
        </button>
      </div>
      <div
        ref={contentRef}
        className="faq-content"
        style={{ maxHeight: `${maxHeight}px` }}
      >
        <p>{p1}</p>
        {img_flat && <div className="img_div"></div>}
        <p>{p2}</p>
        <p>{p3}</p>
      </div>
    </div>
  )
}

export default FAQItem
