// src/components/StrategyCard/StrategyCard.js
import React from 'react'
import './StrategyCard.scss' // 导入 SCSS 文件

const StrategyCard = ({ item, onClick, bgColor }) => {
  const formatCurrency = (amount) => {
    // 去掉美元符号并转换为数字
    const numericAmount = parseFloat(amount.replace(/[^0-9.-]+/g, ''))
    // 格式化为美元格式
    return `$${numericAmount.toLocaleString('en-US')}`
  }
  return (
    <div className="card" onClick={() => onClick(item.title)}>
      {item.title === 'Balance' && (
        <div className="balancedFixedIcon">
          <div className="balanceFixedIcon_icon"></div>
        </div>
      )}
      <h2>{item.title === 'Balance' ? 'Balanced' : item.title} Strategy </h2>
      <div className="Steady Strateg">
        <div className="inner_part_flex">
          <div className="apy">
            {item.apy}%<span>APY*</span>
          </div>
          <div className="risk">
            <div
              className="risk-indicator"
              style={{ backgroundColor: bgColor }}
            ></div>
            <span>Risk</span>
            <div className="progress-bar">
              <div
                className="progress"
                style={{
                  width: `${item.apy}%`,
                  backgroundColor: bgColor,
                  height: 9 + 'px',
                  borderRadius: 31 + 'px'
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="details">
          <div className="detail-item">
            <div>Leverage (spot / futures)</div>
            <div className="detail-value">{item.leverage}</div>
          </div>
          <div className="detail-item">
            <div>Coins Used**</div>
            <div className="detail-value">{item.coin_used}</div>
          </div>
          <div className="detail-item">
            <div>NAV</div>
            <div className="detail-value">
              {formatCurrency(item.invested.toFixed(0))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StrategyCard
