import React, { useEffect, useCallback, useMemo, useState } from 'react'
import './index.scss'
import FooterNav from '@/componets/FooterNav/FooterNav.js'
import StrategyCard from '@/componets/StrategyCard/StrategyCart.js'
import Loading from '@/componets/Loading/Loading.js'
import { useSelector, useDispatch } from 'react-redux'
import { fetchGetStrategySummary } from '@/store/modules/public'
import { useNavigate } from 'react-router-dom'

// 使用 React.memo 优化 StrategyCard 组件，防止不必要的重新渲染
const MemoizedStrategyCard = React.memo(StrategyCard)

const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [totalInvested, setTotalInvested] = useState(0)
  const [totalStrategies, setTotalStrategies] = useState(0)

  const strategySummary = useSelector(
    (state) => state.public.strategy_summary_all
  )

  // 使用 useMemo 优化数据转换
  const data = useMemo(() => {
    return Object.entries(strategySummary)
      .map(([title, data]) => ({
        title: title.charAt(0).toUpperCase() + title.slice(1),
        ...data
      }))
      .sort((a, b) => {
        const order = ['Steady', 'Balance', 'Turbo'] // 注意这里的排序顺序要和转换后的title一致
        return order.indexOf(a.title) - order.indexOf(b.title)
      })
  }, [strategySummary])

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await dispatch(fetchGetStrategySummary())
      setIsLoading(false)
    }
    fetchData()
  }, [dispatch])

  // 使用 useCallback 优化 handleCardClick 函数
  const handleCardClick = useCallback(
    (title) => {
      navigate(
        `/strategy?strategy=` + title.charAt(0).toLowerCase() + title.slice(1)
      )
    },
    [navigate]
  )

  useEffect(() => {
    if (strategySummary && Object.keys(strategySummary).length) {
      // 处理响应数据
      let k = Object.keys(strategySummary).map((k) => {
        return {
          strategy: k,
          apy: strategySummary[k].apy,
          invested: strategySummary[k].invested,
          coin_used: strategySummary[k].coin_used,
          leverage: strategySummary[k].leverage
        }
      })

      // 按照apy排序
      k.sort(function (a, b) {
        var keyA = a.apy,
          keyB = b.apy
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })

      // 计算总投资额
      let totalInv = 0
      k.forEach((d) => {
        totalInv += d.invested
      })
      totalInv = Math.ceil(totalInv)
      setTotalInvested(totalInv.toLocaleString())
      setTotalStrategies(k.length)
    }
  }, [strategySummary])

  const bgColor = {
    Balance: '#fea945',
    Steady: '#52c85d',
    Turbo: '#dc4b4b'
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <div>
      <div className="top_part">
        {' '}
        <div className="gradient-text second-liner-text">
          Non-custodial crypto trading strategies
        </div>
        <div className="num_doller">
          <span className="span">${totalInvested}</span> invested across{' '}
          {totalStrategies} strategies
        </div>
      </div>

      {/* StrategyCard 渲染 */}
      <div className="pc_card_flex">
        {' '}
        {data.map((item) => (
          <MemoizedStrategyCard
            item={item}
            key={item.title}
            onClick={() => handleCardClick(item.title)}
            bgColor={bgColor[item.title]}
          />
        ))}
      </div>

      <div className="outer">
        <div className="icon_gray"></div>
        <div className="outer_content_div_flex">
          <div className="content_div">
            StoneRiver Technology’s Strategies lets you tap into proven
            arbitrage methods from the safety of your own trading account.
          </div>
        </div>
      </div>

      <div className="outer_small_div">
        <div className="small_content_1">*7-days average APY</div>
        <div className="small_content_2">
          **Top coins by open interest in derivate contracts of the coins
        </div>
      </div>

      {/* Footer区域 */}

      <FooterNav />
    </div>
  )
}

export default Home
