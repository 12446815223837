import React from 'react'
import './index.scss'
import FooterNav from '@/componets/FooterNav/FooterNav.js'

const Disclaimer = () => {
  return (
    <div className="term_container">
      <div className="term_title gradient-text">Terms And Conditions</div>
      <div className="term_content-box">
        <div>
          Please read these Terms and Conditions (“Terms”) carefully as they
          form a binding legal agreement between you and StoneRiver (“we” or
          “our” and its derivatives). Our website (https://api.stoneriver.io/)
          and any other sites or subdomains of StoneRiver (collectively, the
          “Site”) and the information on it are controlled by StoneRiver. These
          Terms govern the use of the Site and apply to all visitors to the Site
          and those who use the services and resources available or enabled via
          the Site (each a “Service” and collectively, the “Services”). By
          accessing the Site or accessing, using or continuing to use the
          Services, you signify that you have read, understand, and agree to be
          bound by these Terms (as amended) in its entirety. If you do not
          agree, you should not use the Site or use the Services. The use of the
          Site and any Services is void where such use is prohibited by, would
          be subject to penalties under Applicable Laws, and shall not be the
          basis for the assertion or recognition of any interest, right, remedy,
          power, or privilege. StoneRiver may modify these Terms at any time and
          in our sole discretion. By using the website you consent to any
          updated Terms. Your non-termination or continued use of any Services
          after the effective date of any amendments, changes, or updates
          constitutes your acceptance of these Terms, as modified by such
          amendments, changes, or updates. We invite you to check this page
          regularly for updates to these Terms.
        </div>

        <h2>1. Definitions</h2>
        <div>
          1.1. In these Terms and all documents incorporated herein by
          reference, the following words have the following meanings unless
          otherwise indicated:
        </div>
        <div>
          1.1.1. “Applicable Law” or “Law” means all laws, statutes, orders,
          regulations, rules, treaties and/or official obligations or
          requirements enacted, promulgated, issued, ratified, enforced, or
          administered by any Government that apply to you or the Site;
        </div>
        <div>
          1.1.2. “AML” means anti-money laundering, including all Laws
          applicable to the Parties prohibiting money laundering or any acts or
          attempted acts to conceal or disguise the identity or origin of;
          change the form of; or more, transfer, or transport, illicit proceeds,
          property, funds, fiat, or Digital Assets, including but not limited to
          the promotion of any unlawful activity such as fraud, tax evasion,
          embezzlement, insider trading, financial crime, bribery, cyber theft
          or hack, narcotics trafficking, weapons proliferation, terrorism, or
          Economic Sanctions violations;
        </div>
        <div>
          1.1.3. “Anti-Corruption” means all Laws applicable to each Party
          prohibiting corruption or bribery of government officials, kickbacks,
          inducement, and other related forms of commercial corruption or
          bribery;
        </div>
        <div>1.1.4. “CTF” means counter-terrorist financing;</div>
        <div>
          1.1.5. “Digital Assets” means a digital representation of value that
          functions as (a) a medium of exchange; (ii) a unit of account; (iii) a
          store of value, and/or (iv) other similar digital representations of
          rights or assets, which is neither issued nor guaranteed by any
          country or jurisdiction, typically including blockchain-based assets
          or rights including sovereign cryptocurrency or virtual currency;
        </div>
        <div>
          1.1.6. “Economic Sanctions” means financial sanctions, trade
          embargoes, export or import controls, anti-boycott, and restrictive
          trade measures enacted, administered, enforced, or penalized by any
          Laws applicable to you or the Site;
        </div>
        <div>
          1.1.7. “Feedback” means any ideas, suggestions, documents, and/or
          proposals about the Site and the Services;
        </div>
        <div>
          1.1.8. “Government” means any national, federal, state, municipal,
          local, or foreign branch of government, including any department,
          agency, subdivision, bureau, commission, court, tribunal, arbitral
          body, or other governmental, government appointed, or
          quasi-governmental authority or component exercising executive,
          legislative, juridical, regulatory, or administrative powers,
          authority, or functions of or pertaining to government
          instrumentality, including any state-owned (majority or greater) or
          controlled business enterprise;
        </div>
        <div>
          1.1.9. “StoneRiver Content” means the Services and the information and
          content available therein;
        </div>
        <div>
          1.1.10. “Personal Information” means any information that allows us to
          identify you or any other individual;
        </div>
        <div>
          1.1.11. “Prohibited Jurisdictions” means any state, country, territory
          or other jurisdiction where your use of the Services would be illegal
          or where your use of the Services would amount to any violation of any
          Applicable Law either by you or by StoneRiver;
        </div>
        <div>
          1.1.12. “Prohibited Conduct” has the meaning set out in section 6 of
          these Terms;
        </div>
        <div>
          1.1.13. “Rewards” means, including but not limited to, any incentives,
          rebates, funding rate earnings or remittances associated with the
          Services;
        </div>
        <div>
          1.1.14. “Terms” means these terms and conditions of services, as they
          may be changed, amended, or updated from time to time;
        </div>
        <div>
          1.1.15. “Third Party Services” means applications and/or services
          hosted by third parties;
        </div>
        <div>
          1.1.16. “User Content” means all data and information provided or
          uploaded by you to the Services;
        </div>

        <h2>2. Disclaimer on the Services Provided</h2>
        <div>
          2.1. STONERIVER IS NOT A BANK, SECURITIES FIRM, FINANCIAL INSTITUTION,
          FINANCIAL SERVICES PROVIDER OR PROVIDER OF SIMILAR SERVICES AND DOES
          NOT PROVIDE INVESTMENT OR FINANCIAL ADVICE, FINANCIAL SERVICES OR
          CONSULTING SERVICES TO USERS OF THE SERVICES. WE ARE SOLELY THE
          PROVIDER OF THE SERVICES AS DEFINED ABOVE. YOUR LOGGED DIGITAL ASSETS
          ARE NOT COVERED BY INSURANCE AGAINST LOSSES OR SUBJECT TO ANY DEPOSIT
          INSURANCE SCHEMES OR PROTECTIONS.
        </div>

        <h2>3. Eligibility</h2>
        <div>
          3.1. By browsing the Site and/or using the Services, you represent
          that (a) you have read, understand, and agree to be bound by the
          Terms, (b) you are 18 years or older, or of the age of majority in
          your local jurisdiction, whichever is higher, and legally capable of
          forming a binding contract with StoneRiver, and (c) you have the
          authority to enter into the Terms personally or on behalf of the legal
          entity for whom you are using the services. The term “you” refers to
          you individually or the legal entity on whose behalf you use the
          Services, as applicable. If you do not agree to be bound by the Terms,
          you may not access or use the Services.
        </div>
        <div>
          3.2. You may not use the Services if you are located in, or are a
          citizen or resident of any Prohibited Jurisdiction. It is your
          obligation to check before using the Services whether you are a user
          from a Prohibited Jurisdiction. We may implement controls to restrict
          access to the Services from any Prohibited Jurisdiction pursuant to
          these Terms. You agree to comply with these Terms even if our methods
          to prevent the use of the Services are not effective or can be
          bypassed.
        </div>

        <h2>4. Use of the Services</h2>
        <div>
          4.1. Prior to using the Services, you should examine your objectives,
          financial resources and risk tolerance to determine whether utilizing
          the Services is appropriate for you. By accessing and using the Site
          and/or Services, you represent that you understand the inherent risks
          associated with using cryptographic and blockchain-based systems and
          that you have a working knowledge of the usage and intricacies of
          Digital Assets. You further understand that the markets for Digital
          Assets are highly volatile. You acknowledge that the cost and speed of
          transacting with cryptographic and blockchain-based systems are
          variable and may increase dramatically at any time. You further
          acknowledge the risk that your Digital Assets may lose some or all of
          their value while they are supplied to the Services.
        </div>
        <div>
          4.2. By using the Services, you further understand and agree that any
          Rewards are not guaranteed and the timing of the Rewards may vary.
          Such rates have no relationship to and may not be competitive with
          benchmark interest rates observed in the market for bank deposit
          accounts. You agree and understand that StoneRiver does not guarantee
          that you will receive these Rewards and that the applicable percentage
          or nominal value is an estimate only and not guaranteed and may change
          at any time.
        </div>
        <div>
          4.3. The Services are evolving and you may be required to accept
          updates to Services, or update third-party software (i.e., browsers,
          hardware wallet firmware, hardware wallet bridge, or OS) in order to
          keep using the Services or access their latest features, including
          security updates. We may update the Services at any time, without
          providing notice.
        </div>
        <div>
          4.4. The Services may provide access to, integrate, or create user
          interfaces or interactions with Third Party Services. Access to Third
          Party Services may be geo-blocked for residents of certain countries.
          You agree that it is impossible for StoneRiver to monitor Third Party
          Services and that you access them at your own risk. Do not share any
          credential, private key, or other sensitive or confidential
          information with any third party without validating their legitimacy.
          Third Party Services are available to you, subject to the terms and
          conditions of each Third Party Services provider. To the extent Third
          Party Services have terms that differ from these Terms, you may be
          required to agree to those terms in order to access the Third Party
          Services. You agree to only use any Third Party Services in compliance
          with the terms and conditions governing such Third Party Services.
          Although we do our best to only provide links to credible and reliable
          Third Party Services, we do not control the terms, policies, or
          performance of any third parties, and we are not responsible for any
          performance, or failure to perform, of any Third Party Services. We do
          not provide customer support for transactions and/or services
          performed by Third Party Services providers. We do not guarantee that
          these Third Party Services are secure (although we do our best to
          include only the reliable ones). We do not guarantee that the Third
          Party Services’ instructions and libraries used for any interactions
          are correct and without any errors.
        </div>
        <div>
          4.5. You are responsible for your User Content, whether publicly
          posted (i.e. in a user forum, if applicable) or privately transmitted
          (i.e. to us in connection with a support request). You are solely
          responsible for the accuracy and completeness of User Content you
          submit, and represent and warrant that you have all rights required in
          order to post such User Content. We may, in our sole discretion,
          delete any User Content that we determine violates these Terms or the
          Applicable Law. To the extent that you provide us with or we may have
          access to Personal Information in connection with your use of the
          Services, we will preserve, safeguard, and use such information as set
          forth in our Privacy Policy . You represent that the Personal
          Information you provide is correct and accurate and undertake to
          update it in case any changes occur.
        </div>
        <div>
          4.6. You must provide all equipment and software necessary to use the
          Services yourself. You are solely responsible for any fees, including
          but not limited to internet connection, mobile or blockchain-related
          transaction fees, that you incur when accessing or using the Services.
        </div>

        <h2>5. Intellectual Property</h2>
        <div>
          5.1. StoneRiver (and/or its suppliers, where applicable) owns all
          right, title and interest in and to the StoneRiver Content and the
          Site. You shall not remove, alter or obscure any copyright, trademark,
          service mark or other proprietary rights notices incorporated in or
          accompanying the StoneRiver Content.
        </div>
        <div>
          5.2. StoneRiver and other related graphics, logos, service marks and
          trade names including but not limited to the StoneRiver Content used
          on or in connection with the Services are intellectual property of
          StoneRiver and may not be used without permission in connection with
          any third-party products or services. Other trademarks, service marks
          and trade names that may appear on or in the Services are the property
          of their respective owners including but not limited to StoneRiver’s
          suppliers.
        </div>
        <div>
          5.3. The StoneRiver Content is protected worldwide by copyright,
          trademark, or other intellectual property protection legally
          available. Subject to the Terms, StoneRiver grants you a personal,
          restricted, non-exclusive, non-transferable, non-sublicensable,
          revocable and limited license to reproduce portions of StoneRiver
          Content solely as required to use the Services for your personal or
          internal business purposes. Unless otherwise specified by StoneRiver
          in a separate license, your right to use any StoneRiver Content is
          subject to these Terms.
        </div>
        <div>
          5.4. You own your User Content. By posting, displaying, sharing or
          distributing User Content on or through the Services, you grant us,
          and the provider of any Third Party Services used in connection with
          the Services, a non-exclusive license to use the User Content solely
          for the purpose of operating the Site and providing the Services.
          Except as prohibited by Applicable Law, we may disclose any
          information in our possession (including User Content) in connection
          with your use of the Services, to (a) comply with any Applicable Law,
          regulation and/or order of a competent authority; (b) enforce these
          Terms, (c) respond to your requests for customer service, (d) protect
          the rights, property or personal safety of StoneRiver, our employees,
          directors, officers, partners, suppliers, customers, agents, or
          members of the public, or (e) for any other purpose legally possible
          reasonably determined by us.
        </div>
        <div>
          5.5. You may provide Feedback to StoneRiver through any means, and you
          grant StoneRiver a fully paid, royalty-free, perpetual, irrevocable,
          worldwide, exclusive, and sublicensable right and license to use the
          Feedback for any purpose.
        </div>

        <h2>6. Prohibited Conduct</h2>
        <div>
          6.1. You may not use the Services for any purpose that is prohibited
          by the Terms or Applicable Law. You may not, including but not limited
          to, (a) use the Site or any Services in order to disguise the origin
          or nature of illicit proceeds of, or to further, any breach of
          Applicable Laws, or to transact or deal in, any contraband Digital
          Assets, fiat, funds, property, or proceeds; (b) use the Site or any
          Services if any Applicable Laws, including but not limited to AML
          Laws, CTF Laws, Anti-Corruption Laws, Economic Sanctions Laws,
          prohibit, penalize, sanction, or expose the Site to liability for any
          Services furnished or offered to you under these Terms; (c) use the
          Site or any of the Services to facilitate, approve, evade, avoid,
          violate, attempt to to violate, aid or abet the violation of, or
          circumvent any Applicable Laws, including but not limited to AML Laws,
          CTF Laws, Anti-Corruption Laws, and Economic Sanctions Laws; (d) use
          the Site or any Services to evade taxes under the Laws of the British
          Virgin Islands or any other jurisdiction(s) applicable to you or the
          Site; (e) use the Site or any Services to engage in conduct that is
          detrimental to StoneRiver or to any other Site user or any other third
          party; (f) use the Site or any Services to: engage or attempt to
          engage in wash trading, spoofing, fictitious trading or price
          manipulation; enter orders or quotes in any Digital Assets market with
          the intent to disrupt, or with reckless disregard for the adverse
          impact on, the orderly conduct of trading or the fair execution of
          transactions; or enter orders or quotes in any Digital Assets market
          with the intent of creating the false impression of market depth or
          market interest; or (g) violate, cause a violation of, or conspire or
          attempt to violate these Terms or Applicable Laws.
        </div>
        <div>
          6.2. By accessing the Services, you also agree not to: (a) license,
          sell, rent, lease, transfer, assign, reproduce, distribute, host or
          otherwise commercially exploit the Services or StoneRiver Content, or
          any portion thereof; (b) frame or enclose any trademark, logo, or
          other StoneRiver Content, (including images, text, page layout or
          form); (c) use any metatags or other “hidden text” using StoneRiver’s
          name or trademarks; (d) modify, translate, adapt, merge, make
          derivative works of, disassemble, decompile, reverse compile or
          reverse engineer any part of the Services (except to the extent this
          restriction is expressly prohibited by Applicable Law); (e) use any
          manual or automated software, devices or other processes (including
          spiders or other data mining tools) to “scrape” or download data from
          the Site or from the Services (except that we grant operators of
          public search engines a revocable permission to do so for the sole
          purpose of creating publicly available searchable indices (but not
          caches or archives) of such content); (f) access the Services in order
          to build similar or competitive services; (g) copy, reproduce,
          distribute, republish, download, display, post or transmit any
          StoneRiver Content except as expressly permitted herein; and (h)
          remove or destroy any copyright notices or other proprietary markings
          contained on or in the Services or StoneRiver Content. StoneRiver, its
          suppliers and service providers reserve all rights not expressly
          granted by these Terms. Any unauthorized use of the Services
          terminates the licenses and/or rights granted by StoneRiver herein.
        </div>
        <div>
          6.3. You shall not (and shall not permit any third party to) take any
          action or make available any content on or through the Services that:
          (a) infringes any intellectual property rights of any person or
          entity; (b) is unlawful, threatening, harassing, defamatory,
          libellous, deceptive, fraudulent, invasive of another’s privacy,
          tortious, obscene, or offensive; (c) is unauthorized or unsolicited
          advertising, junk or bulk email; (d) involves commercial activities
          and/or sales, such as contests, sweepstakes, barter, advertising, or
          pyramid schemes; (e) impersonates any person or entity, including any
          employee or representative of StoneRiver; (f) interferes with the
          proper functioning of the Services; (g) engages in any potentially
          harmful acts directed against the Site or the Services, including
          violating any security features, introducing malware, viruses, worms,
          or similar harmful code into the Site or the Services; or (h) attempts
          to do any of the foregoing.
        </div>

        <h2>7. Non-Solicitation; No Investment Advice</h2>
        <div>
          7.1. You agree and understand that all usage of the Site and the
          Services is considered unsolicited, which means that you have not
          received any investment, legal, tax or financial advice from us in
          connection with any such usage, and that we do not conduct a
          suitability review of any such usage.
        </div>
        <div>
          7.2. All information provided within the Site or the Services is for
          informational purposes only and should not be construed as investment,
          legal, tax or financial advice.
        </div>

        <h2>8. No Fiduciary Duties</h2>
        <div>
          8.1. THESE TERMS ARE NOT INTENDED TO AND DO NOT CREATE OR IMPOSE ANY
          FIDUCIARY DUTIES ON US. TO THE FULLEST EXTENT PERMITTED BY LAW, YOU
          ACKNOWLEDGE AND AGREE THAT WE OWE NO FIDUCIARY DUTIES OR LIABILITIES
          TO YOU OR ANY OTHER PARTY, AND THAT TO THE EXTENT ANY SUCH DUTIES OR
          LIABILITIES MAY EXIST AT LAW OR IN EQUITY, THOSE DUTIES AND
          LIABILITIES ARE HEREBY IRREVOCABLY DISCLAIMED, WAIVED, AND ELIMINATED.
          YOU FURTHER AGREE THAT THE ONLY DUTIES AND OBLIGATIONS THAT WE OWE YOU
          ARE THOSE SET OUT EXPRESSLY IN THESE TERMS.
        </div>

        <h2>9. Investigations</h2>
        <div>
          9.1. Although StoneRiver does not generally monitor your use of the
          Services, if StoneRiver becomes aware of or suspects any possible
          violations by you of any provision of the Terms or any Applicable Law,
          StoneRiver may investigate such violations and, at its sole
          discretion, take any actions it deems appropriate including but not
          limited to terminating your access to the Services, putting your
          access to the Services on hold, putting your account on hold or
          terminating it permanently.
        </div>

        <h2>10. Indemnification</h2>
        <div>
          10.1. You agree to indemnify and hold StoneRiver harmless from any
          losses, costs, liabilities and expenses (including legal fees)
          relating to or arising out of: (a) your use of, or inability to use,
          the Site or the Services; (b) your violation of the Terms; (c) your
          violation of any rights of another party, including but not limited to
          any other users of the Services; or (d) your violation of any
          Applicable Laws. StoneRiver may, at its own discretion, assume the
          exclusive defense and control of any matter otherwise subject to
          indemnification by you, in which event you shall fully cooperate with
          StoneRiver in asserting any available defenses. This provision does
          not require you to indemnify StoneRiver for any fraud, gross
          negligence, or wilful misconduct of StoneRiver.
        </div>

        <h2>11. Disclaimer of Warranties</h2>
        <div>
          11.1. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS, WITH ALL FAULTS, AND STONERIVER EXPRESSLY DISCLAIMS ALL
          WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND ARISING FROM
          OR RELATED TO THESE TERMS OR YOUR USE OF THE SERVICES, INCLUDING BUT
          NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
          FOR A PARTICULAR PURPOSE. YOU ACKNOWLEDGE THAT, TO THE EXTENT
          PERMITTED BY APPLICABLE LAW, ALL RISK OF USE OF THE SERVICES RESTS
          ENTIRELY WITH YOU.
        </div>
        <div>
          11.2. STONERIVER IS NOT LIABLE, AND YOU AGREE NOT TO SEEK TO HOLD
          STONERIVER LIABLE, FOR THE CONDUCT OF THIRD PARTIES ON OR ACCESSED VIA
          THE SERVICES, INCLUDING THE USE OF THIRD PARTY SERVICES. THE RISK OF
          DAMAGE, LOSS OR INJURY FROM USE OF SUCH THIRD PARTY SERVICES RESTS
          ENTIRELY WITH YOU.
        </div>

        <h2>12. Limitation of Liability</h2>
        <div>
          12.1. By using the Services, you acknowledge and agree: (a) to be
          fully responsible and liable for your trading and non-trading actions
          and inactions on the site and all gains and losses sustained from your
          use of the Site and any of the Services; and (b) to be fully
          responsible for safeguarding access to, and any information provided
          through the Site and any of the Services.
        </div>
        <div>
          12.2. IN NO EVENT WILL STONERIVER BE LIABLE FOR ANY LOST PROFITS,
          REVENUE OR DATA, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
          DAMAGES, OR DAMAGES OR COSTS DUE TO LOSS OF PRODUCTION OR USE,
          BUSINESS INTERRUPTION, OR PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES
          ARISING OUT OF OR IN CONNECTION WITH THE SERVICES, WHETHER OR NOT
          STONERIVER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND
          REGARDLESS OF THE LIABILITY ASSERTED. IF ANY DISCLAIMER OR LIMITATION
          IN THIS SECTION 12.2. IS DEEMED INVALID, UNENFORCEABLE OR INEFFECTIVE
          BY THE APPLICABLE LAW, SUCH DISCLAIMER OR LIMITATION SHALL BE DEEMED
          MODIFIED TO APPLY TO THE MAXIMUM EXTENT PERMITTED BY THE APPLICABLE
          LAW.
        </div>
        <div>
          12.3. UNDER NO CIRCUMSTANCES WILL STONERIVER BE LIABLE TO YOU FOR
          DAMAGES ARISING OUT OF YOUR USE OF THE SERVICES EXCEEDING 100 USD. IF
          ANY DISCLAIMER OR LIMITATION IN THIS SECTION 12.3. IS DEEMED INVALID,
          UNENFORCEABLE OR INEFFECTIVE BY THE APPLICABLE LAW, SUCH DISCLAIMER OR
          LIMITATION SHALL BE DEEMED MODIFIED TO APPLY TO THE MAXIMUM EXTENT
          PERMITTED BY THE APPLICABLE LAW.
        </div>
        <div>
          12.4. THE LIMITATIONS IN SECTIONS 12.1. AND 12.2. WILL NOT APPLY TO
          DAMAGES CAUSED BY FRAUD, GROSS NEGLIGENCE, OR WILFUL MISCONDUCT OF
          STONERIVER, OR TO THE EXTENT SUCH LIMITATIONS ARE PRECLUDED BY
          APPLICABLE LAW (IN WHICH CASE STONERIVER’S LIABILITY SHALL BE
          INCREASED TO THE MINIMUM AMOUNT REQUIRED TO COMPLY WITH SUCH LAW).
        </div>

        <h2>13. Term and Termination</h2>
        <div>
          13.1. The Terms commence on the date when you accept them (as
          described in the preamble above) and remain in full force and effect
          for so long as you access the Site or use the Services, unless
          terminated earlier in accordance with the Terms.
        </div>
        <div>
          13.2. StoneRiver may, at any time and for any reason, cease providing
          the Site or any or all of the Services, and/or terminate the Terms.
          Without limiting the foregoing, we may also terminate your access to
          any or all of the StoneRiver Content and/or the Services.
        </div>
        <div>
          13.3. Upon termination of the Services, your right to use the Services
          will automatically terminate immediately. StoneRiver will not have any
          liability whatsoever to you for any suspension or termination. All
          provisions of the Terms which by their nature should survive
          termination of these Terms or the Services will do so, including but
          not limited to Sections 3, 5, 7, 7,10, 11, 12, 13.4 and 13.
        </div>

        <h2>14. Miscellaneous</h2>
        <div>
          14.1. Communications to you from StoneRiver use electronic means,
          whether made via the Services or sent via email, or whether StoneRiver
          posts notices on the Services. For contractual purposes, you (1)
          consent to receive communications from StoneRiver in an electronic
          form; and (2) agree that all terms and conditions, agreements,
          notices, disclosures, and other communications that StoneRiver
          provides to you electronically satisfy any legal requirement that such
          communications would satisfy if it were to be in writing in a physical
          copy. The foregoing shall not affect your statutory rights.
        </div>
        <div>
          14.2. Where StoneRiver requires that you provide an email address, you
          are responsible for providing StoneRiver with your most current and
          correct email address. In the event that the last email address you
          provided to StoneRiver is not valid, or for any reason is not capable
          of delivering to you any notices required/permitted by the Terms,
          StoneRiver’s dispatch of the email containing such notice will
          nonetheless constitute effective notice. You may give notice to
          StoneRiver at the following email address only:
          info@api.stoneriver.io. Such notice shall be deemed delivered when the
          receipt of such notice is confirmed by StoneRiver to the email address
          you provided to StoneRiver.
        </div>
        <div>
          14.3. The Terms, and your rights and obligations hereunder, may not be
          assigned, subcontracted, delegated or otherwise transferred by you
          without the prior written consent of StoneRiver. Any attempt by you to
          assign these Terms without written consent is void. These Terms, and
          any of the rights, duties, and obligations contained herein, are
          freely assignable by StoneRiver without notice or your consent.
        </div>
        <div>
          14.4. StoneRiver will not be liable for any delay or failure to
          perform resulting from causes outside its reasonable control,
          including but not limited to acts of God, war, terrorism, riots,
          pandemics or epidemics, embargos, acts of civil or military
          authorities, fire, floods, accidents, strikes or shortages of
          transportation facilities, fuel, energy, labor or materials.
        </div>
        <div>
          14.5. If you have any questions, feedback, complaints or claims with
          respect to the Services, please contact us at: info@api.stoneriver.io.
          We will do our best to address your concerns.
        </div>
        <div>
          14.6. These Terms and any action related thereto shall be governed by
          and shall be construed in accordance with the Laws of the British
          Virgin Islands. All claims and disputes arising under or relating to
          this Agreement are to be settled exclusively by the competent courts
          in the British Virgin Islands. For the avoidance of doubt, and without
          limiting the generality of the foregoing, this provision expressly
          applies to any claim, whether in tort, contract or otherwise, against
          StoneRiver. You irrevocably and unconditionally agree and consent to
          the jurisdiction and venue of the courts of the British Virgin
          Islands, and you waive any objections thereto, including under the
          doctrine of forum non conveniens or other similar doctrines. You and
          StoneRiver agree that any Party hereto may bring claims against the
          others only on an individual basis and not as a plaintiff or class
          member in any purported class or representative action or proceeding.
          Any relief awarded to any one user of the Services cannot and may not
          affect any other users of the Services.
        </div>
        <div>
          14.7. Any waiver of any provision of the Terms on one occasion will
          not be deemed a waiver of any other provision or of such provision on
          any other occasion. Any delay or failure in exercising any right shall
          not constitute a waiver unless such waiver is confirmed in writing.
        </div>
        <div>
          14.8. If any part of any section of these Terms is held invalid or
          unenforceable, that part will be construed in a manner to reflect, as
          nearly as possible, its original meaning while remaining valid and
          enforceable or if not possible shall be deemed deleted and the
          remaining part of the section and/or of these Terms will remain in
          full force and effect.
        </div>
        <div>
          14.9. These Terms are the final, complete and exclusive agreement
          between you and StoneRiver with respect to the subject matter hereof
          and supersedes and merges all prior discussions, agreements or
          representations between you and StoneRiver with respect to such
          subject matter.
        </div>
      </div>
      <div className="pc_footerNav">
        <FooterNav />
      </div>
    </div>
  )
}

export default Disclaimer
