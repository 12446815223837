import { configureStore } from '@reduxjs/toolkit'
import publicReducer from './modules/public'
import privateReducer from './modules/private'

export default configureStore({
  reducer: {
    public: publicReducer,
    private: privateReducer
  }
})
