// src/apis/private.js
import { request } from '@/utils/index.js'

// 登录 API
const loginAPI = (formData) => {
  return request({
    url: '/sign_in',
    method: 'POST',
    data: formData
  })
}

// 注册 API
const signUpAPI = (formData) => {
  return request({
    url: '/register',
    method: 'POST',
    data: formData
  })
}

// 验证码校验
const verifyCodeAPI = (formData) => {
  console.log(formData)
  return request({
    url: '/verify-code',
    method: 'POST',
    data: formData
  })
}

// getClientProfitSummary
const getClientProfitSummary = () => {
  return request({
    url: '/api/client_profit_summary',
    method: 'GET'
  })
}

// getClientApySummary
const getClientApySummary = () => {
  return request({
    url: '/api/client_apy_summary',
    method: 'GET'
  })
}

// getClientInvestOverview
const getClientInvestOverview = () => {
  return request({
    url: '/api/client_invest_overview',
    method: 'GET'
  })
}

// getClientStrategyHistoryApy
const getClientStrategyHistoryApy = (timeWindow) => {
  return request({
    url: `/api/client_strategy_history_apy?time_window=${timeWindow}`,
    method: 'GET'
  })
}

// getClientStrategyHistoryProfit
const getClientStrategyHistoryProfit = (timeWindow) => {
  return request({
    url: `/api/client_strategy_history_profit?time_window=${timeWindow}`,
    method: 'GET'
  })
}

// getClientBills
const getClientBills = () => {
  return request({
    url: '/api/client_bills',
    method: 'GET'
  })
}

// getClientProfile
const getClientProfile = () => {
  return request({
    url: '/api/client_profile',
    method: 'GET'
  })
}

// addApiKey
const addApyKey = (key_name, key_value) => {
  return request({
    url: '/add_api_key',
    method: 'POST',
    data: JSON.stringify({ key_name, key_value })
  })
}

export {
  loginAPI,
  signUpAPI,
  getClientProfitSummary,
  getClientInvestOverview,
  getClientApySummary,
  getClientStrategyHistoryApy,
  getClientStrategyHistoryProfit,
  getClientBills,
  getClientProfile,
  addApyKey,
  verifyCodeAPI
}
