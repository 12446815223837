import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input, Button, TextArea, Toast } from 'antd-mobile'
import './index.scss'
import FooterNav from '@/componets/FooterNav/FooterNav.js'
import { fetchPostContactUs } from '@/store/modules/public'
import CheckMark from '@/componets/CheckMark/Checkmark.js'
import FailedIcon from '@/componets/FailedIcon/FailedIcon.js'

const ContactUs = () => {
  const dispatch = useDispatch()
  const formRef = useRef(null)

  const onCommitFormData = async (values) => {
    console.log('Success:', values)
    const res = await dispatch(fetchPostContactUs(values))
    if (res.success) {
      Toast.show({
        icon: <CheckMark />,
        duration: 3000
      })
      // formRef.current?.resetFields() // 清空表单
    } else {
      Toast.show({
        content: res.message,
        icon: <FailedIcon />,
        duration: 3000
      })
    }
  }

  return (
    <div className="contact-us-container-div">
      <div className="contactUs_wrap_div">
        <h2>Contact Us</h2>
        <Form
          ref={formRef}
          name="form"
          onFinish={onCommitFormData}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              className="submit-button"
            >
              Submit
            </Button>
          }
        >
          <div className="name-fields">
            <div className="field-container">
              <div className="field-label">Name*</div>
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'Name is required' }]}
              >
                <Input placeholder="Your name" />
              </Form.Item>
            </div>
            <div className="field-container">
              <div className="field-label">Email*</div>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'The input is not valid E-mail!' }
                ]}
              >
                <Input placeholder="Your email" />
              </Form.Item>
            </div>
          </div>
          <div className="field-container">
            <div className="field-label">Subject*</div>
            <Form.Item
              name="subject"
              rules={[{ required: true, message: 'Subject is required' }]}
            >
              <Input placeholder="Message" />
            </Form.Item>
          </div>
          <div className="field-container">
            <div className="field-label">Message</div>
            <Form.Item name="message">
              <TextArea placeholder="Message" rows={4} />
            </Form.Item>
          </div>
        </Form>
      </div>
      <div className="contact_us_footerNav">
        <FooterNav />
      </div>
    </div>
  )
}

export default ContactUs
